import React from "react"
import Layout from '../components/layout'

const About = () => {
	return (
		<Layout pageTitle="About Page">
			<p>This is my about page!</p>
		</Layout>
	)
}

export default About
